import { get } from "lodash";

// Expect a rich text node that has a content array prop
export default function getLegalServicesFromRichText({ content = [] }, { baseUrl, locale = "en-US" }) {
  const legalServiceNodes = content.filter((c) => get(c, "data.target.sys.contentType.sys.id") === "legalService");
  if (legalServiceNodes.length === 0) return [];

  return legalServiceNodes.map((lsn) => ({
    "@context": "https://schema.org",
    "@type": "LegalService",
    name:
      get(lsn, `data.target.fields.businessName['${locale}']`) ||
      `${get(lsn, `data.target.fields.firstName['${locale}']`) || ""} ${
        get(lsn, `data.target.fields.lastName['${locale}']`) || ""
      }`.trim(),
    legalName: get(lsn, `data.target.fields.businessName['${locale}']`),
    telephone: get(lsn, `data.target.fields.phoneNumber['${locale}']`, ""),
    email: get(lsn, `data.target.fields.email['${locale}']`, ""),
    priceRange: get(lsn, `data.target.fields.priceRange['${locale}']`, ""),
    image:
      get(lsn, `data.target.fields.image['${locale}'].fields.image['${locale}'].fields.file['${locale}'].url`) != null
        ? {
            "@type": "ImageObject",
            url: `https:${get(
              lsn,
              `data.target.fields.image['${locale}'].fields.image['${locale}'].fields.file['${locale}'].url`
            )}`,
          }
        : null,
    address: {
      "@type": "PostalAddress",
      streetAddress: get(lsn, `data.target.fields.address[${locale}].fields.street1[${locale}]`),
      addressLocality: get(lsn, `data.target.fields.address[${locale}].fields.city[${locale}]`),
      addressRegion: get(lsn, `data.target.fields.address[${locale}].fields.state[${locale}]`),
      postalCode: get(lsn, `data.target.fields.address[${locale}].fields.zipcode[${locale}]`),
      addressCountry: "US",
    },
  }));
}
