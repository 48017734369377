import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { queryClient } from "../queries/queryClient";
import Portal from "../overlays/Portal";
import { useLoggedInUser } from "./hooks/useUser";
import getMyUpsolveEntryUrl from "../Links/getMyUpsolveEntryUrl";

export const SignupModal = ({ onClose, url }) => {
  const targetUrl = url || getMyUpsolveEntryUrl();
  const iframeRef = useRef();
  useEffect(() => {
    const listener = (event) => {
      if (event.origin !== UPSOLVE_FILER_URL) return;
      if (event.data && event.data.success) {
        queryClient.invalidateQueries(["user"]);
        onClose();
        window.top.location.href = targetUrl;
      }
    };
    window.addEventListener("message", listener, false);
    return () => window.removeEventListener('message', listener);
  }, []);
  return (
    <StyledModalBackground onClick={onClose}>
      <StyledSignupModal role="dialog" onClick={(ev) => ev.preventDefault()}>
        <span className="close" onClick={onClose}>&#x2715;</span>
        <iframe ref={iframeRef} src={targetUrl} />
      </StyledSignupModal>
    </StyledModalBackground>
  );
};

export const SignupModalWrapper = ({ children, url }) => {
  const [showModal, setShowModal] = useState(false);
  const { data: user, isFetched } = useLoggedInUser();
  const showModalHandler = useCallback(() => {
    if (isFetched && user) {
      window.location.href = url;
    } else {
      setShowModal(true);
    }
  }, [user, isFetched]);
  return (
    <React.Fragment>
      {children(showModalHandler)}
      {showModal && (
        <Portal>
          <SignupModal onClose={() => setShowModal(false)} url={url} />
        </Portal>
      )}
    </React.Fragment>
  );
}

const StyledSignupModal = styled.div`
  margin: 20px auto;
  width: 600px;
  height: 90vh;
  padding: 4px;
  background: white;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray[900]};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
    width: 100vw;
    height: 100vh;
    margin: 0px;
    border-radius: 0px;
  }

  .close {
    padding: 4px;
    border: 1px solid ${(props) => props.theme.colors.gray[900]};;
    border-radius: 16px;
    overflow: hidden;
    background: white;
    cursor: pointer;
    position: absolute;
    right: 4px;
    width: 32px;
    height: 32px;
    text-align: center;
    font-weight: bold;
  }

  iframe {
    flex: 1;
    border: none;
  }
`;

const StyledModalBackground = styled.div`
  // Fullscreen Positioning
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  // Background
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  // Centering
  overflow: auto;
`;