import { get } from "lodash";
import richTextNodeToString from "./richTextNodeToString";

// Expect a rich text node that has a content array prop
export default function getFaqFromRichText({ content = [] }) {
  const faqNode = content.find((c) => get(c, "data.target.sys.contentType.sys.id") === "faq");
  return faqNode != null
    ? faqNode.data.target.fields.questions["en-US"].map((question) => ({
        question: question.fields.text["en-US"],
        answer: richTextNodeToString(get(question, "fields.answers['en-US'][0].fields.text['en-US']")),
      }))
    : null;
}
