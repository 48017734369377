import config from "../../../site-config";

const upsolveJSONLD = {
  "@context": "http://schema.org",
  "@type": ["Organization", "NGO", "LegalService"],
  address: {
    "@context": "http://schema.org",
    "@type": "PostalAddress",
    addressLocality: "New York City",
    addressRegion: "NY",
    postalCode: "11201",
    streetAddress: "150 Court Street, 2nd Floor",
    addressCountry: "US",
  },
  url: "https://upsolve.org",
  name: "Upsolve",
  sameAs: [
    "https://www.facebook.com/upsolvebankruptcy/",
    "https://twitter.com/upsolvebk",
    "https://www.instagram.com/upsolve/",
    "https://youtube.com/upsolve",
    "https://www.linkedin.com/company/upsolve/",
    "https://labs.robinhood.org/portfolio/upsolve/",
    "https://www.ffwd.org/alum/upsolve/",
    "https://www.ycombinator.com/companies/12095",
    "https://www.charitynavigator.org/ein/821736267",
    "https://angel.co/company/upsolve-1",
    "https://www.crunchbase.com/organization/prudence-lasb",
    "https://github.com/upsolve",
    "https://stackshare.io/companies/upsolve",
    "https://www.guidestar.org/profile/82-1736267",
    "https://www.open990.org/org/821736267/upsolve-inc/",
    "https://namati.org/network/organization/upsolve-inc/",
    "https://www.211la.org/resources/site/upsolve",
    "https://211utah.org/index.php?option=com_cpx&task=resource.view&id=3080913&search_history_id=86766439&code=DM",
  ],
  description: config.siteDescription,
  telephone: "+1-347-850-2656",
  image: "https://static.upsolve.org/app-preview-bubble.png",
  logo: {
    "@context": "http://schema.org",
    "@type": "ImageObject",
    url: "https://static.upsolve.org/logos/upsolve-logo-512.png",
  },
  areaServed: {
    "@context": "http://schema.org",
    "@type": "AdministrativeArea",
    address: {
      "@context": "http://schema.org",
      "@type": "PostalAddress",
      addressCountry: "US",
    },
  },
  contactPoint: [],
  priceRange: "Free",
};

export default upsolveJSONLD;
