import React from 'react';
import { A, ButtonLink } from '@upsolve/ui';

import getMyUpsolveEntryUrl from './getMyUpsolveEntryUrl';
import { SignupModalWrapper } from '../signup/SignupModal';

// NOTE: we've decided to disable the iframe modal on 1/11/2022
const DISABLE_SIGNUP_MODAL = true;

export const MyUpsolveLinkBase = (Component) => (props) => {
  const { href, onClick, children, ...otherProps } = props || {};

  // extra sanity check for non-my.upsolve.org links
  // (fallback to plain, non-signup iframe modal wrapped elem)
  if (DISABLE_SIGNUP_MODAL || targetUrl.indexOf(UPSOLVE_FILER_URL) !== 0) {
    return (
      <Component href={href} onClick={onClick} {...otherProps}>
        {children}
      </Component>
    );
  }

  return (
    <SignupModalWrapper url={href}>
      {(showModal) => (
        <Component
          {...otherProps}
          href={href}
          onClick={(ev) => { 
            ev.preventDefault();
            if (typeof onClick === 'function') onClick();
            showModal();
          }}>
          {children}
        </Component>
      )}
    </SignupModalWrapper>
  );
}

export const MyUpsolveLink = MyUpsolveLinkBase(A);
export const MyUpsolveButtonLink = MyUpsolveLinkBase(ButtonLink);