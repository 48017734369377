import { format, isValid } from "date-fns";
import { get } from "lodash";

// Expect SEO Node
export default function videoToJSONLD(node) {
  if (node == null) return null;
  const { video } = node;
  if (video == null) return null;

  let contentUrl;
  let embedUrl;
  if (get(video, "file.url") != null) {
    contentUrl = (get(video, "video.file.url") || "").replace(/^\/\//, "https://");
  }
  if (video.youtubeId != null) {
    embedUrl = `https://www.youtube.com/embed/${video.youtubeId}`;
    if (contentUrl == null) {
      contentUrl = `https://www.youtube.com/watch?v=${video.youtubeId}`;
    }
  }

  // Allow fallbacks to SEO node values
  return {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: video.title || node.title,
    description: get(video, "description.description") || node.description,
    thumbnailUrl: (video.thumbnailImage || [])
      .map((thumbnail) => (get(thumbnail, "image.file.url") || "").replace(/^\/\//, "https://"))
      .filter((url) => url),
    uploadDate: isValid(new Date(video.uploadedAt || node.createdAt))
      ? format(new Date(video.uploadedAt || node.createdAt), "yyyy-MM-dd")
      : null,
    duration: video.duration,
    contentUrl,
    embedUrl,
  };
}
