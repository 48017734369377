import React from "react";
import styled from "styled-components";
import CloseButton from "../../../static/images/close-button.svg";

const Z_OVERLAY_BACKDROP = 999;
type TViewportCenteredFixed = {
  zIndex?: string;
};

export const ViewportCenteredFixed = styled.div<TViewportCenteredFixed>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ zIndex }) => zIndex ?? Z_OVERLAY_BACKDROP + 1};
`;

export const ModalHeader = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0.75em 1em 0.5em;
  border-bottom: 1px solid ${(p) => p.theme.colors.white[700]};
`;

const StyledFullScreenModalClose = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;

  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
`;

export const FullScreenModalClose = (props: any) => (
  <StyledFullScreenModalClose {...props}>
    <CloseButton />
  </StyledFullScreenModalClose>
);

const StyledModalClose = styled.div`
  align-self: end;
  @media (max-height: ${(props) => props.theme.breakpoints[500]}) {
    position: absolute;
    top: 10px;
    right: 14px;
  }

  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
`;

export const ModalClose = (props: any) => (
  <StyledModalClose {...props}>
    <CloseButton />
  </StyledModalClose>
);
export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0.5em;
  max-height: 80vh;
  overflow-y: auto;
`;

export const ModalActions = styled.div`
  display: flex;
  padding: 0 1em 1em;
  & > button {
    display: flex;
    width: 100%;
  }
`;

export const ModalFooter = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${(p) => p.theme.colors.white[700]};
  padding: 1em;
`;

export const StyledModal = styled.div<{ css?: any }>`
  width: 420px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${(p) => p.theme.breakpoints[500]}) {
    height: 100%;
    border-radius: 0;
  }
  ${(props) => {
    if (props.css) return props.css;
  }}
`;

// TODO: figure out the right way to type styled-components "css"
export const Modal: React.FC<{ css?: any }> = ({ children, css, ...props }) => {
  return (
    <StyledModal css={css} onClick={(e) => e.stopPropagation()} {...props}>
      {children}
    </StyledModal>
  );
};
