import { EVENTS } from "@upsolve/shared";
import { TTrackingEvent } from "@upsolve/shared/dist/types";
import { track } from "./track";

export default async function trackComponentAction({
  actionId,
  actionMethod,
  componentName,
  componentVersion,
  pageContext,
}: TTrackingEvent["fields"]) {
  return track(EVENTS.COMPONENT_ACTION, {
    actionId,
    actionMethod,
    componentName,
    componentVersion,
    pageContext,
  });
}