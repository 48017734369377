import { getYear } from "date-fns";
import { get, startCase } from "lodash";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { Location } from "@reach/router";
import { STATES } from "@upsolve/shared";
import config from "../../../site-config";
import pathToBreadcrumb from "./pathToBreadcrumb";
import breadcrumbToJSONLD from "./breadcrumbToJSONLD";
import howToToJSONLD from "./howToToJSONLD";
import videoToJSONLD from "../SEO/videoToJSONLD";
import articleToJSONLD from "../SEO/articleToJSONLD";
import faqToJSONLD from "../SEO/faqToJSONLD";
import webApplicationJSONLD from "./webApplicationJSONLD";
import siteLinksSearchBoxJSONLD from "./siteLinksSearchBoxJSONLD";
import getFaqFromRichText from "../Contentful/getFaqFromRichText";
import getHowToFromRichText from "../Contentful/getHowToFromRichText";
import getLegalServicesFromRichText from "../Contentful/getLegalServicesFromRichText";
import imagesMappedToUrls from "./imagesMappedToUrls";
import richTextNodeToString from "../Contentful/richTextNodeToString";
import {
  CITY_PAGE,
  CITY_SUB_PAGE,
  STATE_PAGE,
  STATE_SUB_PAGE,
  STATE_BANKRUPTCY_FORMS,
  STATE_MEANS_TEST,
} from "../Programmatic/pageTypes";

const SEOChild = ({ contentNode, contentPath, noIndex, onRender, pageNode, seoNode, ...props }) => {
  const path = props.location.pathname;
  let { authors = [], description, image, robots, title, url, createdAt, updatedAt } = props;
  let tags;
  // JSONLD Vars
  let jsonLD = [];
  let ldMapFaq = null;
  let ldMapHowTo = null;
  let ldMapArticle = null;
  let ldMapLegalServices = null;
  let ldMapVideo = null;
  let ldWebApp = false;

  // SETUP
  // - Setup from SEO Node Model
  if (seoNode != null) {
    title = seoNode.title;
    description = seoNode.description;
    image = imagesMappedToUrls(seoNode.images || [])[0] || "https://upsolve.org/images/meta-legalaid.png";
    if (seoNode.structuredType === "howTo") {
      ldMapHowTo = howToToJSONLD(seoNode);
    }
    if (seoNode.structuredType === "faq") {
      ldMapFaq = faqToJSONLD(seoNode);
    }
    if (seoNode.structuredType === "article") {
      // OPTIMIZE: somehow articles crash build
      ldMapArticle = articleToJSONLD({ path, seo: seoNode });
    }
    if (seoNode.video != null) {
      // OPTIMIZE: I think this is ok, but just disabling to try deploying
      ldMapVideo = videoToJSONLD(seoNode);
    }
    if (seoNode.includeRatings) {
      ldWebApp = true;
    }
  }

  // - Setup from Page model if SEO node values weren't present
  if (pageNode != null) {
    let pageTitle;
    let pageDescription;
    const cityText = startCase(get(pageNode, "locality.city") || "");
    const stateText = STATES[(get(pageNode, "locality.state") || "").toUpperCase()];
    const thisYear = getYear(new Date());
    if (pageNode.type === STATE_PAGE) {
      pageTitle = `How to File Bankruptcy for Free in ${stateText} (${thisYear} Guide)`;
      pageDescription = `Live in ${stateText} and need help filing for bankruptcy and can't afford an attorney? Our legal aid nonprofit guides ${stateText} debtors through the chapter 7 process.`;
      ldWebApp = true;
    }
    if (pageNode.type === STATE_SUB_PAGE) {
      pageTitle = pageNode.title;
      pageDescription =
        get(pageNode, "content.json") != null
          ? richTextNodeToString(get(pageNode, "summary.json") || get(pageNode, "content.json"), 155)
          : config.siteDescription;
    }
    if (pageNode.type === STATE_BANKRUPTCY_FORMS) {
      pageTitle = `${thisYear} Bankruptcy Forms for ${stateText}`;
      pageDescription = `Want to file Chapter 7 bankruptcy? You're going to want to understand what the free bankruptcy forms for ${stateText}.`;
    }
    if (pageNode.type === STATE_MEANS_TEST) {
      pageTitle = `${stateText} Means Test ${thisYear}`;
      pageDescription = `The Means Test determines your eligibility for Chapter 7 bankruptcy in ${stateText}. Upsolve gives free help to those who are eligible and low income.`;
    }
    if (pageNode.type === CITY_PAGE) {
      pageTitle = `File Bankruptcy for Free in ${cityText}, ${stateText} (${thisYear} Guide)`;
      pageDescription = `Live in ${cityText} ${stateText} and need help filing for bankruptcy and can't afford an attorney? Our legal aid nonprofit guides ${cityText} ${stateText} debtors through the chapter 7 process.`;
    }
    if (pageNode.type === CITY_SUB_PAGE) {
      pageTitle = `${pageNode.title} (${new Date().getFullYear()})`;
      pageDescription =
        (get(pageNode, "summary.json") || get(pageNode, "content.json")) != null
          ? richTextNodeToString(get(pageNode, "summary.json") || get(pageNode, "content.json"), 155)
          : config.siteDescription;
    }
    if (title == null && pageTitle != null) title = pageTitle;
    if (description == null && pageDescription != null) description = pageDescription;
    // TODO: Allow citations to get onto JSON+LD
  }

  // - Setup from Content Node Model (legacy of Learn Articles)
  if (contentNode != null) {
    if (!title) title = contentNode.seoTitle || contentNode.title;
    if (!description) description = contentNode.seoDescription || get(contentNode, "articleExcerpt.articleExcerpt");
    if (!image)
      image = get(contentNode, "seoImage.image.file.url") ||  "https://upsolve.org/images/meta-legalaid.png";
    if (/^\/\//.test(image)) image = image.replace(/^\/\//, "https://");
    ldMapArticle = articleToJSONLD({
      url: `https://upsolve.org${path}`,
      content: contentNode,
    });
    // -- Video
    if (contentNode.video != null) {
      ldMapVideo = videoToJSONLD({
        description: contentNode.description,
        video: contentNode.video,
        createdAt: get(contentNode.video, "uploadedAt") || contentNode.createdAt,
      });
    }
    // -- Tags
    if (contentNode.tags != null) {
      tags = contentNode.tags.map((t) => t.label);
    }
    // -- Authors
    if (contentNode.author) authors = [contentNode.author];
    if (contentNode.authors) authors = contentNode.authors;
  }

  // - Fallbacks from props
  if (jsonLD.length === 0 && Array.isArray(props.jsonLD)) jsonLD = props.jsonLD;
  if (props.includeRatings) ldWebApp = true;
  // - Defaults if none set
  if (title == null) {
    console.error(`SEO Title Missing: ${url || path}`);
    title = config.siteTitle;
  }
  if (description == null) {
    console.error(`SEO Description Missing: ${url || path}`);
    description = config.siteDescription;
  }
  if (image == null) image = "https://upsolve.org/images/meta-legalaid.png";
  if (url == null && path != null) url = `https://upsolve.org${path}`;
  if (!createdAt) createdAt = get(pageNode, "createdAt") || get(contentNode, "createdAt");
  if (!updatedAt) updatedAt = get(pageNode, "updatedAt") || get(contentNode, "updatedAt");

  // FORMATTING
  // - Add Upsolve to title if none included
  if (/upsolve/gi.test(title || "") === false && title.length <= 49) {
    title = `${title} - Upsolve`;
  }
  // - Prefix date string on descriptions on pages/articles
  // TODO: Search snippets are showing double dates (google assigned and our prefix). It looks sloppy
  // if (contentNode || pageNode) {
  //   const prefixDate = updatedAt || createdAt || new Date();
  //   description = `${format(new Date(prefixDate), 'MMM d, yyyy')} - ${description}`;
  // }

  // CRAWLERS
  if (noIndex === true) robots = "NONE";

  // JSON+LD
  // - breadcrumb
  if (path != null && jsonLD.find((j) => j["@type"] === "BreadcrumbList") == null) {
    if (pathToBreadcrumb(path).length > 0) jsonLD.push(breadcrumbToJSONLD(pathToBreadcrumb(path)));
  }
  // - extract from content
  if (get(pageNode || contentNode, "content.json") != null) {
    if (getHowToFromRichText(get(pageNode || contentNode, "content.json"), { baseUrl: path }) != null) {
      ldMapHowTo = howToToJSONLD({
        howTo: getHowToFromRichText(get(pageNode || contentNode, "content.json"), { baseUrl: path }),
        page: pageNode,
      });
    }
    if (getFaqFromRichText(get(pageNode || contentNode, "content.json"), { baseUrl: path }) != null) {
      ldMapFaq = faqToJSONLD({
        faq: getFaqFromRichText(get(pageNode || contentNode, "content.json"), { baseUrl: path }),
      });
    }
    if (getLegalServicesFromRichText(get(pageNode || contentNode, "content.json"), { baseUrl: path }).length > 0) {
      ldMapLegalServices = getLegalServicesFromRichText(get(pageNode || contentNode, "content.json"), {
        baseUrl: path,
      });
    }
  }
  // - backups
  if (pageNode != null) {
    if (ldMapFaq == null && ldMapArticle == null) ldMapArticle = articleToJSONLD({ path, page: pageNode });
  }
  // - compile
  if (path.includes("/search")) {
    jsonLD.push(siteLinksSearchBoxJSONLD);
  }
  if (ldMapLegalServices) jsonLD.push(...ldMapLegalServices);
  if (ldMapArticle && !jsonLD.some((j) => j["@type"] === "Article")) {
    jsonLD.push(ldMapArticle);
    // Include ratings stars on every learn article in addition to article jsonld
    if (url.includes("/learn/")) ldWebApp = true;
  }
  if (ldMapVideo && !jsonLD.some((j) => j["@type"] === "VideoObject")) jsonLD.push(ldMapVideo);
  // Replace HowTo JSON+LD sitewide with webApplication JSONLD. Has higher CTR
  if (ldMapHowTo || jsonLD.some((j) => j["@type"] === "HowTo")) {
    // jsonLD.push(ldMapHowTo);
    ldWebApp = true;
    jsonLD = jsonLD.filter((j) => j["@type"] !== "HowTo");
  }
  if (ldWebApp && !jsonLD.some((j) => j["@type"] === "WebApplication")) jsonLD.push(webApplicationJSONLD);
  if (ldMapFaq && !jsonLD.some((j) => j["@type"] === "FAQPage")) jsonLD.push(ldMapFaq);

  // CALLBACK WHEN RENDERING SO WE CAN PASS FINAL JSONLD TO TRACKING COMPONENTS
  if (onRender && typeof onRender === "function") onRender({ jsonLD, title });

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="keywords" content={config.siteKeywords} />

      {createdAt != null && <meta property="article:published_time" content={createdAt} />}
      {updatedAt != null && <meta property="article:modified_time" content={updatedAt} />}
      {authors &&
        authors.length > 0 &&
        authors.map((author) => <meta key={author.name} property="article:author" content={author.name} />)}
      {tags && tags.length > 0 && tags.map((tag) => <meta key={tag} property="article:tag" content={tag} />)}

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={jsonLD.some((j) => j["@type"] === "Article") ? "article" : "website"} />
      <meta property="og:site_name" content="Upsolve" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Upsolvebk" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {robots != null && <meta name="robots" content={robots} />}

      {jsonLD.map((json, jsonIndex) => (
        <script key={`${json["@type"]}-${jsonIndex}`} type="application/ld+json">
          {JSON.stringify(json)}
        </script>
      ))}
    </Helmet>
  );
};

const SEO = (props) => <Location>{({ location }) => <SEOChild {...props} location={location} />}</Location>;

export default SEO;
