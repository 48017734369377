import React, { Fragment } from "react";

// Amp is off by default (false).
const AmpContext = React.createContext(false);

const { Provider: AmpProvider, Consumer: AmpConsumer } = AmpContext;

// Helper component which enables amp on children, including deep children.
function Ampify({ children }) {
  return (
    <Fragment>
      {/* value={true}, but eslint complains about react/jsx-boolean-value */}
      <AmpProvider value>{children}</AmpProvider>
    </Fragment>
  );
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

// Higher order component which injects the given component with the amp
// context prop (whether amp is currently enabled or not).
//
// If you need a prop name other than 'amp', pass in a second parameter with
// the desired name.
//
// Normal usage:
//   // MyComponent.jsx
//   function MyComponent({ amp }) {
//      return amp ? 'amp enabled' : 'amp disabled';
//   }
//
//   export default withAmp(MyComponent);
//
//
// Usage with a custom amp key:
//   // CustomAmpKey.jsx
//   function CustomAmpKey({ myCustomAmpKey }) {
//      return myCustomAmpKey ? 'amp enabled' : 'amp disabled';
//   }
//
//   export default withAmp(CustomAmpKey, 'myCustomAmpKey');
function withAmp(WrappedComponent, ampKey = "amp") {
  class ComponentWithAmp extends React.Component {
    render() {
      return (
        <AmpConsumer>
          {(amp) => {
            const newProps = { ...this.props, [ampKey]: amp };
            return <WrappedComponent {...newProps} />;
          }}
        </AmpConsumer>
      );
    }
  }

  // Change the display name for debugging purposes.
  ComponentWithAmp.displayName = `withAmp(${getDisplayName(WrappedComponent)})`;

  return ComponentWithAmp;
}

export { withAmp, Ampify, AmpProvider, AmpConsumer };
