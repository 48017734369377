import { navigate } from "gatsby";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { IconSearch, IconX, Input } from "@upsolve/ui";
import trackComponentAction from "../analytics/trackComponentAction";

const trackingProps = {
  componentName: "UpsolveSearchBar",
  componentVersion: 0,
};

/**
 * UpsolveSearchBar
 * onClose exists for header navbar
 * onSearch exists for header navbar to trigger close after search
 * - v0: Nerdwallet Replication
 *
 * @component
 * @version 0
 */
const UpsolveSearchBar = ({ autoFocus = false, defaultValue, onClose, onSearch, position }) => {
  const [searchString, setSearchString] = useState(defaultValue);
  function runSearch(ev) {
    if (ev) ev.preventDefault();
    if (searchString) {
      trackComponentAction({ ...trackingProps, actionId: "upsolveSearchBar.search", actionMethod: "click" });
      navigate(`/search?q=${searchString}`);
      if (onSearch) onSearch(searchString);
    }
  }

  return (
    <StyledUpsolveSearch position={position}>
      <form onSubmit={runSearch}>
        <IconSearch onClick={runSearch} />
        <label htmlFor="upsolve-search-bar">Search Upsolve</label>
        <Input
          id="upsolve-search-bar"
          autoFocus={autoFocus}
          onChange={(ev) => setSearchString(ev.target.value)}
          placeholder="Search Upsolve"
          size="lg"
          type="text"
          value={searchString}
        />
        {onClose != null && (
          <div
            onClick={() => {
              if (onClose) onClose();
            }}
          >
            <IconX />
          </div>
        )}
      </form>
    </StyledUpsolveSearch>
  );
};

const StyledUpsolveSearch = styled.div`
  ${(props) => {
    if (props.position === "navbar") {
      return css`
        position: relative;
        z-index: 998;
        background: ${(props) => props.theme.colors.white[700]};
        box-shadow: ${(props) => props.theme.effects.shadow[300]};
        padding: 0.5em 1.25em;
      `;
    }
  }}
  width: 100%;
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid ${(props) => props.theme.colors.brand[500]};
    max-width: 1100px;
    margin: 0.25em auto 0.5em;
  }
  label {
    width: 0;
    overflow: hidden;
  }
  input {
    margin: 0;
    background: none;
    outline: none;
    border: none;
  }
  svg {
    height: 24px;
    max-height: 24px;
    width: 24px;
    max-width: 24px;
    path {
      fill: ${(props) => props.theme.colors.gray[900]};
    }
    &:last-of-type {
      cursor: pointer;
    }
  }
`;

export default UpsolveSearchBar;
