//compare with:
//https://www.creditkarma.com/calculators/credit-cards/debt-repayment
//gets within ~$10 of the same estimate

//(these two are for compound interest on savings but functionally the same)
//https://www.investor.gov/financial-tools-calculators/calculators/compound-interest-calculator
//https://www.nerdwallet.com/calculator/compound-interest-calculator

//original reference (please update with a better source if you find one)
//https://www.reddit.com/r/askmath/comments/1bk7119/what_is_the_best_way_to_calculate_compound/

type years = number;
type dollars = number;
type fraction = number;

export const getCompoundedInterest = (
  currentDebt: dollars,
  annualInterestRate: fraction,
  timeToCompound: years,
  monthlyDebtPayoffAmount: dollars
) => {
  const numberOfCompoundingPeriodsPerYear = 12; //compound every month
  const compoundedDebt =
    currentDebt *
    Math.pow(
      1 + annualInterestRate / numberOfCompoundingPeriodsPerYear,
      timeToCompound * numberOfCompoundingPeriodsPerYear
    );
  const compoundedInterestFromDebt = compoundedDebt - currentDebt;

  //This part of the equation is calculating the effects of paying the loan as
  //we go. This is needed because we are paying down the principal throughout
  //debt repayment. TODO: Check if we want this, but pretty sure we do as this
  //gets us to about the same result as credit karma's debt repayment calculator
  const monthlyContribution = -1 * monthlyDebtPayoffAmount;

  const compoundedMonthlyContributions =
    (monthlyContribution *
      (Math.pow(
        1 + annualInterestRate / numberOfCompoundingPeriodsPerYear,
        timeToCompound * numberOfCompoundingPeriodsPerYear
      ) -
        1)) /
    (annualInterestRate / numberOfCompoundingPeriodsPerYear);

  const compoundedInterestFromMonthlyContributions =
    compoundedMonthlyContributions + monthlyDebtPayoffAmount * timeToCompound * 12;

  return compoundedInterestFromDebt + compoundedInterestFromMonthlyContributions;
};

// from chatGPT, consistant with https://www.calculator.net/repayment-calculator.html?cloanamount=20%2C000&cinterestrate=25&ccompound=monthly&cpayback=month&cyears=5&cmonths=0&cpaybackway=a&cpaybackwayamount=500&printit=0&x=Calculate#payment-result

// Function to calculate the number of months to pay off the principal debt
function calculateMonthsToPayOffDebt(
  currentDebt: number,
  annualInterestRate: number,
  adjustedMonthlyFunds: number
): number {
  const r = annualInterestRate / 12; // Monthly interest rate
  const A = adjustedMonthlyFunds; // Adjusted monthly payment

  if (A <= currentDebt * r) {
    // If available funds can never pay off the interest, this option is not affordable
    return -1;
  }

  // Calculate the number of months using the amortization formula
  const n = Math.log(A / (A - currentDebt * r)) / Math.log(1 + r);

  return n;
}

// Need better name for this
// Function to calculate the total payment including one-time fees
export function getPaymentInfo(currentDebt: number, annualInterestRate: number, availableMonthlyFunds: number) {
  const monthsToPayOffDebt = calculateMonthsToPayOffDebt(currentDebt, annualInterestRate, availableMonthlyFunds);

  if (monthsToPayOffDebt < 0) {
    return { monthsInRepayment: 0, totalPayment: 0, canAfford: false };
  }

  // Total payment for the debt plus the one-time fees
  const totalPaymentForDebt = monthsToPayOffDebt * availableMonthlyFunds;

  const totalPayment = totalPaymentForDebt;

  return { monthsInRepayment: monthsToPayOffDebt, totalPayment: totalPayment, canAfford: true };
}
