import ratingsJSONLD from "./ratingsJSONLD";

const upsolveOrgObj = {
  "@type": "Organization",
  name: "Upsolve",
  url: "https://upsolve.org/",
};

const webApplicationJSONLD = {
  "@context": "https://schema.org",
  "@type": "WebApplication",
  name: "Upsolve Bankruptcy Tool",
  applicationCategory: "FinanceApplication",
  applicationSubCategory: "Bankruptcy",
  aggregateRating: ratingsJSONLD.aggregateRating,
  offers: {
    "@type": "Offer",
    price: "0",
    priceCurrency: "USD",
  },
  operatingSystem: "All",
  browserRequirements: "Requires Javascript. Requires HTML5",
  inLanguage: "EN",
  author: upsolveOrgObj,
  creator: upsolveOrgObj,
  copyrightHolder: upsolveOrgObj,
  publisher: upsolveOrgObj,
  copyrightYear: new Date().getFullYear(),
  isFamilyFriendly: "true",
  screenshot: "https://static.upsolve.org/app-preview-bubble.png",
  permissions: "Full Internet Access",
  description: "Upsolve is a free bankruptcy tool that helps you file chapter 7 bankruptcy on your own.",
  softwareVersion: "6.3.0",
};

export default webApplicationJSONLD;
