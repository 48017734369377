import { theme } from "@upsolve/ui";
import React, { ReactNode, useState } from "react";
import styled from "styled-components";

export const Tooltip = ({ children, content }: { children: ReactNode; content: ReactNode }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="tooltip-wrapper"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      onClick={() => setIsVisible(!isVisible)} // Close on click
      style={{ display: "inline-block", position: "relative" }}
    >
      {children}
      {isVisible && (
        <StyledTooltipContent
          onClick={() => setIsVisible(false)} // Close when clicking on tooltip
        >
          {content}
        </StyledTooltipContent>
      )}
    </div>
  );
};

const StyledTooltipContent = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${theme.colors.white[100]};
  padding: 12px;
  width: 200px;
  border-radius: 8px;
  margin-top: 4px;
  cursor: pointer;
`;
