// Expecting a particular schema
export default function faqToJSONLD(node) {
  if (node == null) return null;
  const { faq } = node;
  if (faq == null) return null;

  const jsonLD = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    mainEntity: faq.map((question) => ({
      "@type": "Question",
      name: question.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: question.answer,
      },
    })),
  };

  return jsonLD;
}
