const bankruptcyCodes = require("../../../content/onBuild/bankruptcyCodes.json");
const privatePricings = require("../../../content/privatePricings.json");

interface IGetConstantComponentValue {
  key: string;
  state?: string;
}

const federalBankruptcyCodeKeyValues = bankruptcyCodes.FEDERAL.reduce((obj, code) => {
  return {
    ...obj,
    [code.codeName]:
      code.limitAggregateSingle > 10000000
        ? "Unlimited"
        : Number(code.limitAggregateSingle).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
  };
}, {});

const getConstantComponentValue = ({ key, state }: IGetConstantComponentValue): string | null => {
  return {
    amendmentFilingFee: "$34",
    attorneyPriceLow: `$${Number((privatePricings[(state || "").toUpperCase()] || {}).low || 500).toLocaleString(
      undefined,
      { minimumFractionDigits: 0, maximumFractionDigits: 0 }
    )}`,
    attorneyPriceHigh: `$${Number((privatePricings[(state || "").toUpperCase()] || {}).high || 1500).toLocaleString(
      undefined,
      { minimumFractionDigits: 0, maximumFractionDigits: 0 }
    )}`,
    federalMinimumWage: "$7.25",
    federalWildcardLimit: federalBankruptcyCodeKeyValues["11 U.S.C. § 522(d)(5)"],
    ...federalBankruptcyCodeKeyValues,
    filingFee: "$338",
    year: String(new Date().getUTCFullYear()),
  }[key];
};

export default getConstantComponentValue;
