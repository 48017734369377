import { get } from "lodash";
import { STATES } from "@upsolve/shared";
import imagesMappedToUrls from "./imagesMappedToUrls";
import videoToJSONLD from "./videoToJSONLD";
import richTextNodeToString from "../Contentful/richTextNodeToString";

// Expecting a particular schema
export default function howToToJSONLD(node) {
  if (node == null) return null;
  const { howTo } = node;
  if (howTo == null) return null;

  const jsonLD = {
    "@context": "http://schema.org",
    "@type": "HowTo",
    step: howTo.steps.map((s, i) => {
      const stepLD = {
        "@type": "HowToStep",
        name: s.title,
        url: s.url,
        image: {
          "@type": "ImageObject",
          url: s.image || `https://static.upsolve.org/images/${i + 1}.png`,
          height: 960,
          width: 960,
        },
        itemListElement: [
          ...(s.directions || []).map((direction) => ({
            "@type": "HowToDirection",
            text: direction,
          })),
          ...(s.tips || []).map((tip) => ({
            "@type": "HowToTip",
            text: tip,
          })),
        ],
      };
      if ((stepLD.itemListElement || []).length === 0) {
        stepLD.text = stepLD.name;
      }
      return stepLD;
    }),
    supply: [],
    tool: [],
  };

  // Get & Set Properties based on Node Type
  // - How To File Bankruptcy
  if (howTo != null && howTo.type === "filingBankruptcy") {
    jsonLD.name = `How to File Bankruptcy${
      get(node, "page.locality.state") != null ? ` in ${STATES[get(node, "page.locality.state").toUpperCase()]} ` : ""
    }for Free`;
    jsonLD.description =
      "Filing for bankruptcy on your own is a possible route for Americans. Upsolve is a free service that helps you.";
    jsonLD.totalTime = "PT3H0M0S";
    jsonLD.estimatedCost = {
      "@type": "MonetaryAmount",
      currency: "USD",
      value: 0,
    };
    jsonLD.supply = [
      {
        "@type": "HowToSupply",
        name: "Smartphone or Computer",
      },
      {
        "@type": "HowToSupply",
        name: "Printer",
      },
    ];
    jsonLD.tool = [
      {
        "@type": "HowToTool",
        name: "Bankruptcy Forms",
      },
    ];
  } else if (howTo != null && howTo.type != null) {
    jsonLD.name = howTo.title;
    jsonLD.description = howTo.description;
    jsonLD.totalTime = howTo.duration || "";
    jsonLD.estimatedCost =
      howTo.estimatedCost != null
        ? {
            "@type": "MonetaryAmount",
            currency: "USD",
            value: howTo.estimatedCost,
          }
        : null;
    jsonLD.supply = howTo.supplies || [
      {
        "@type": "HowToSupply",
        name: "Smartphone or Computer",
      },
    ];
    jsonLD.tool = howTo.tools || [];
    // - How To Custom (fall back to page)
  } else if (howTo != null && howTo.type == null) {
    jsonLD.name = howTo.title || get(node, "page.title", "");
    jsonLD.description =
      howTo.description ||
      (get(node, "page.summary.json") != null ? richTextNodeToString(get(node, "page.summary.json")) : "");
    jsonLD.totalTime = get(howTo, "duration", "");
    jsonLD.supply = (howTo.supplies || []).map((s) => ({
      "@type": "HowToSupply",
      name: s,
    }));
    jsonLD.tool = (howTo.tools || []).map((t) => ({
      "@type": "HowToTool",
      name: t,
    }));
    if (howTo.estimatedCost != null) {
      jsonLD.estimatedCost = {
        "@type": "MonetaryAmount",
        currency: "USD",
        value: howTo.estimatedCost,
      };
    }
  }
  // - Fall backs with SEO if missing values
  if (jsonLD.name == null) jsonLD.name = node.title;
  if (jsonLD.description == null) jsonLD.description = node.description;

  // Extras
  // - Media
  if (node.video != null) {
    jsonLD.video = videoToJSONLD(node);
  } else if (node.images != null && node.images.length > 0) {
    jsonLD.image = {
      "@type": "ImageObject",
      url: imagesMappedToUrls(node.images)[0],
    };
  }

  // Validate
  if ((jsonLD.step || []).length <= 1) {
    console.warn(`How-To has ${(jsonLD.step || []).length} steps. Expecting 2 or more.`);
    return null;
  }
  return jsonLD;
}
