import { startCase } from "lodash";
import { STATES } from "@upsolve/shared";

export default function pathToBreadcrumb(slug) {
  const crumbs = [];
  let baseSlug = "";
  slug
    .split("/")
    // If path is AMP, skip
    .filter((slugPart) => slugPart !== "amp")
    // Otherwise crumb away!
    .forEach((slugPart) => {
      baseSlug += `${slugPart}/`.toLowerCase().trim();
      crumbs.push({
        slug: baseSlug,
        title: startCase(STATES[slugPart.toUpperCase()] || slugPart),
      });
    });
  return crumbs.filter((c) => c.title.length > 0);
}
