export const BARE = "bare";
export const LEARN_ARTICLE = "learnArticle";

export const CITY_PAGE = "cityPage";
export const CITY_SUB_PAGE = "citySubPage";
export const CITY_ATTORNEY_PRICING = "cityAttorneyPricing";

export const STATE_PAGE = "statePage";
export const STATE_SUB_PAGE = "stateSubPage";
export const STATE_BANKRUPTCY_FORMS = "stateBankruptcyForms";
export const STATE_MEANS_TEST = "stateMeansTest";

export const JOB_LISTING = "jobListing";
