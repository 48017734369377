import { transparentize } from "polished";
import styled, { css } from "styled-components";

const Z_OVERLAY_BACKDROP = 61;

export type TStyledOverlayBackdropProps = {
  isExiting?: boolean;
};

export const StyledOverlayBackdrop = styled.div<TStyledOverlayBackdropProps>`
  @keyframes sidebarBackdropMounting {
    0% {
      background: ${(props) => transparentize(1, props.theme.colors.black[100])};
    }
    100% {
      background: ${(props) => transparentize(0.4, props.theme.colors.black[100])};
    }
  }
  @keyframes sidebarBackdropUnMounting {
    0% {
      background: ${(props) => transparentize(0.4, props.theme.colors.black[100])};
    }
    100% {
      background: ${(props) => transparentize(1, props.theme.colors.black[100])};
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_OVERLAY_BACKDROP};
  background: ${(props) => transparentize(1, props.theme.colors.brand[500])};
  animation: sidebarBackdropMounting 500ms forwards;
  ${(props) => {
    if (props.isExiting) {
      return css`
        animation: sidebarBackdropUnMounting 250ms forwards;
      `;
    }
  }}
`;
