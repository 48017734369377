import styled from "styled-components";
import React, { useState } from "react";
import trackComponentAction from "../analytics/trackComponentAction";
import { navigate } from "gatsby-link";

const trackingProps = {
  componentName: "HelloBar",
  componentVersion: "0",
};
const HelloBar = (props: { helloBarText: any; helloBarUrl: string; helloBarTrackingActionId: string }) => {
  //when we update the action,the localstorage key updates so all users see the latest bar.
  const helloBarLocalStorageKey = "Upsolve.helloBarClosed." + props.helloBarTrackingActionId;
  let isHelloBarClosed = typeof window !== "undefined" && !!window.localStorage.getItem(helloBarLocalStorageKey);
  const [showHelloBar, setShowHelloBar] = useState(!isHelloBarClosed);
  if (!showHelloBar) return <></>;
  return (
    <HelloBarWrapper
      onClick={() => {
        trackComponentAction({
          ...trackingProps,
          actionId: props.helloBarTrackingActionId,
          actionMethod: "click",
        });
        navigate(props.helloBarUrl);
      }}
    >
      <HelloBarText>{props.helloBarText}</HelloBarText>
      <CloseButton
        onClick={(e) => {
          e.stopPropagation();
          localStorage.setItem(helloBarLocalStorageKey, "true");
          setShowHelloBar(false);
        }}
      >
        X
      </CloseButton>
    </HelloBarWrapper>
  );
};
const HelloBarText = styled.div`
  flex: 1;
`;
const CloseButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
`;
const HelloBarWrapper = styled.div`
  text-align: center;
  justify-content: space-between;
  display: flex;
  background: #ede8da;
  font-size: 18px;
  letter-spacing: 0.25px;
  padding: 10px;
  cursor: pointer;
  :hover {
    background: ${(props) => props.theme.colors.brand[800]};
  }
`;

export default HelloBar;
