export default function breadcrumbToJSONLD(layers = []) {
  if (layers == null || layers.length === 0) return null;
  return {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: layers.map(({ slug, title }, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: title,
      item: `https://upsolve.org${slug[0] !== "/" ? "/" : ""}${slug}${slug.slice(-1) !== "/" ? "/" : ""}`,
    })),
  };
}
