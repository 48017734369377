import { StaticQuery, graphql } from "gatsby";
import { kebabCase, orderBy } from "lodash";
import React, { useState } from "react";
import Link from "gatsby-link";
import styled from "styled-components";
import { HELP, STATES } from "@upsolve/shared";
import { A, ACSS, H3, H5, H6, Small } from "@upsolve/ui";

import { learnTagsSortedByPriority } from "./learnTagPriority";
import UpsolveSearchBar from "../search/UpsolveSearchBar";

const EXCLUDE_STATES = ["AS", "FM", "GU", "MH", "MP", "PR", "PW", "VI"];

const LearnArticleAccordian = ({ fundamentalLearnArticles }) => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <LearnArticleAccordianUL isShowing={isShowing}>
      <ul>
        {(fundamentalLearnArticles || []).map((article) => (
          <li key={article.url || article.slug}>
            <Link aria-busy="false" to={article.url || `/learn/${article.slug}/`}>
              {article.title}
            </Link>
          </li>
        ))}
      </ul>
      {!isShowing ? (
        <Small onClick={() => setIsShowing(true)}>+ Show Articles</Small>
      ) : (
        <Small onClick={() => setIsShowing(false)}>- Hide Articles</Small>
      )}
    </LearnArticleAccordianUL>
  );
};

const LearnArticleAccordianUL = styled.div`
  padding: 0.5em 1em 1em;
  ul {
    transition: 250ms;
    overflow: hidden;
    height: ${(props) => (props.isShowing ? "initial" : "0px")};
    margin: 0.25em;
  }
  li {
    margin: 0.75em 0 1.25em;
  }
  small {
    display: flex;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.gray[700]};
    &:hover {
      cursor: pointer;
    }
  }
`;

const LearnListingPanel = () => (
  <StaticQuery
    query={graphql`
      query LearnListingPanelQuery {
        tags: allContentfulLearnArticleTag(filter: { type: { eq: "category" } }) {
          nodes {
            value
            label
            type
            fundamentalLearnArticles {
              slug
              title
            }
          }
        }
      }
    `}
    render={(data) => {
      const tags = data.tags.nodes || [];
      return (
        <LinkListingPanel>
          <div className="wrapper">
            <div className="prompt">
              <H3>Learning Center</H3>
              <p>Research and understand your options with our articles and guides.</p>
              <Link aria-busy="false" to="/learn/">
                Go to Learning Center →
              </Link>
              <p>Already an Upsolve user?</p>
              <A aria-busy="false" href={HELP.HELP_CONTACT_URL} target="_blank" rel="noopener noreferrer">
                Read Support Articles →
              </A>
            </div>
            <div className="content">
              <UpsolveSearchBar />
              <div className="content__grid">
                {orderBy(
                  tags,
                  [(t) => Object.values(learnTagsSortedByPriority).indexOf(t.value)], ["asc"]).map((tag) => (
                    <div key={tag.value} className="content__grid__item">
                      <Link to={`/learn/category/${kebabCase(tag.value)}/`}>
                        <H5 as="p">{tag.label} ➜</H5>
                      </Link>
                      <LearnArticleAccordian fundamentalLearnArticles={tag.fundamentalLearnArticles} />
                    </div>
                ))}
                <div key="states" className="content__grid__item">
                  <H5 as="p">State Guides ➜</H5>
                  <LearnArticleAccordian
                    fundamentalLearnArticles={Object.keys(STATES)
                      .filter((abbrev) => !EXCLUDE_STATES.includes(abbrev))
                      .map((st) => ({ url: `/${st.toLowerCase()}/`, title: STATES[st] }))}
                  />
                </div>
              </div>
            </div>
          </div>
        </LinkListingPanel>
      );
    }}
  />
);

const LinkListingPanel = styled.div`
  background: ${(props) => props.theme.colors.white[800]};
  .wrapper {
    display: flex;
    justify-content: center;
    max-width: 1080px;
    margin: 0 auto;
    padding: 3em 1em;
    & > div {
      display: flex;
      flex-direction: column;
    }
  }
  .prompt {
    margin-right: 1em;
    margin-bottom: 2em;
    max-width: 240px;
    a {
      ${ACSS}
    }
  }
  .content__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1em;
    row-gap: 1em;
    margin: 1em 0;
  }
  .content__grid__item {
    margin: 0;
    line-height: 110%;
    border-radius: 3px;
    overflow: hidden;
    background: white;
    box-shadow: ${(props) => props.theme.effects.shadow[300]};
    p {
      transition: 250ms;
      border-bottom: 1px solid ${(props) => props.theme.colors.white[300]};
      padding: 1em;
      background: ${(props) => props.theme.colors.brand[500]};
      color: white;
      font-size: 14px;
      margin: 0;
    }
    a {
      ${ACSS}
      font-size: 14px;
    }
    li {
      font-size: 0.9em;
    }
    &:hover {
      p {
        background: ${(props) => props.theme.colors.brand[300]};
      }
    }
  }

  @media screen and (max-width: 45em) {
    .wrapper {
      flex-direction: column;
    }
    .prompt {
      max-width: 100%;
      & > p,
      & > a {
        margin: 0.4em 0;
      }
    }
    .content__grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default LearnListingPanel;
