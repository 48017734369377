import { get, kebabCase } from "lodash";

import imagesMappedToUrls from "./imagesMappedToUrls";
import richTextNodeToString from "../Contentful/richTextNodeToString";
import upsolveJSONLD from "./upsolveJSONLD";

// Handle learn article, seo node, or page node
export default function articleToJSONLD(node) {
  if (node == null) return null;
  const { content, page, seo } = node;
  if (content == null && page == null && seo == null) return null;

  let authors = [];
  let citation;
  let datePublished;
  let dateModified;
  let description;
  let headline;
  let image;
  let url;

  // Set Attributes
  // - SEO Node
  if (seo != null) {
    datePublished = get(node, "article.createdAt");
    dateModified = get(node, "article.updatedAt");
    description = seo.description;
    headline = seo.title;
    image = imagesMappedToUrls(node.images || []);
    if (image == null || (image || []).length === 0) {
      image = "https://upsolve.org/images/meta-legalaid.png";
    }
    // - Page/Content Node
  } else if (page != null || content != null) {
    headline = get(page || content, "title");
    description =
      get(page, "summary.json") || get(page, "content.json") != null
        ? richTextNodeToString(get(page, "summary.json") || get(page, "content.json"), 155)
        : get(content, "articleExcerpt.childMarkdownRemark.articleExcerpt") ||
          get(content, "articleExcerpt.articleExcerpt") ||
          get(page || content, "description");
    datePublished = get(page || content, "createdAt");
    dateModified = get(page || content, "updatedAt");
    image = (
      get(content, "image.file.url") ||
      get(content, "seoImage.image.file.url") ||
      get(content, "coverImage.image.file.url") ||
      "//upsolve.org/images/meta-legalaid.png"
    ).replace(/^\/\//, "https://");
    // --- Authors
    if ((page || content).authors) {
      authors = (page || content).authors.map((author) => ({
        authorBio: get(author, "biography.biography"),
        authorEmail: get(author, "email"),
        authorName: get(author, "name"),
        authorSameAs: (get(author, "profiles") || []).map((p) => p.url),
        authorTitle: get(author, "role"),
      }));
    } else if ((page || content).author) {
      authors = [
        {
          authorBio: get(page || content, "author.biography.biography"),
          authorEmail: get(page || content, "author.email"),
          authorName: get(page || content, "author.name"),
          authorSameAs: (get(page || content, "author.profiles") || []).map((p) => p.url),
          authorTitle: get(page || content, "author.role"),
        },
      ];
    }
    // --- Citations
    citation = get(page || content, "citations", []).map((c) => ({
      "@type": "CreativeWork",
      author: c.authorName,
      publication: c.publication,
      publisher: c.organizationName,
      url: c.url,
      datePublished: c.publishedAt,
    }));
  }

  // Set urls
  if (node.path != null) {
    url = `https://upsolve.org${node.path}`;
  } else if (node.url != null) {
    url = node.url;
  }

  return {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": url,
    },
    headline,
    description,
    image,
    datePublished: datePublished != null ? new Date(datePublished).toISOString() : new Date().toISOString(),
    dateModified: dateModified != null ? new Date(dateModified).toISOString() : undefined,
    author:
      authors.length > 0
        ? authors.map((author) => ({
            "@context": "http://schema.org",
            "@type": "Person",
            description: author.authorBio,
            email: author.authorEmail,
            jobTitle: author.authorTitle,
            knowsAbout: "Bankruptcy, Chapter 7, Debt",
            name: author.authorName,
            sameAs: author.authorSameAs,
            url: `https://upsolve.org/authors/${kebabCase(author.authorName).toLowerCase()}/`,
          }))
        : upsolveJSONLD,
    publisher: upsolveJSONLD,
    citation,
  };
}
