import { graphql, StaticQuery } from "gatsby";
import React, { Component } from "react";
import styled from "styled-components";
import { A, P, theme } from "@upsolve/ui";

import ImageInContext from "../Media/ImageInContext";
import Page from "./Page.div";

const AboutContainer = styled.div`
  background: ${theme.colors.white["800"]};
  text-align: center;
  div.images {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      &.team {
        border-radius: 210px;
        height: 210px;
        width: 210px;
        min-width: 210px;
        max-width: 210px;
        border: 6px solid ${(props) => props.theme.colors.brand["500"]};
      }
      &:not(.team) {
        width: 140px;
        height: 100%;
        max-width: 140px;
        max-height: 60px;
        @media (max-width: 45em) {
          margin: 0.75em;
        }
      }
    }
    @media (max-width: 45em) {
      flex-direction: column;
    }
  }
`;

class AboutPanel extends Component {
  render() {
    return (
      <AboutContainer id="about">
        <Page padding="2em 1em">
          <StaticQuery
            query={graphql`
              query AboutPictures {
                team: file(base: { eq: "team.jpg" }) {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                lsc: file(base: { eq: "lsc.png" }) {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 140) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                robinHood: file(base: { eq: "robinhood.png" }) {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 140) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }

                fastForward: file(base: { eq: "fast-forward.png" }) {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 140) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            `}
            render={(data) => {
              return (
                <div className="images">
                  <ImageInContext alt="Legal Services Corporation" fluid={data.lsc.childImageSharp.fluid} />
                  <ImageInContext alt="Robin Hood" fluid={data.robinHood.childImageSharp.fluid} />
                  <ImageInContext className="team" alt="The Upsolve Team" fluid={data.team.childImageSharp.fluid} />
                  <ImageInContext alt="Fast Forward" fluid={data.fastForward.childImageSharp.fluid} />
                  <img alt="Y-Combinator" src={require("/static/images/y-combinator.jpg")} width="50px" />
                </div>
              );
            }}
          />
          <P>
            <b>Upsolve is a 501(c)(3) nonprofit that started in 2016.</b> Our mission is to help low-income families
            resolve their debt and fix their credit using free software tools. <A href="/team/">Our team</A> includes
            debt experts and engineers who care deeply about making the financial system accessible to everyone. We have
            world-class funders that include the U.S. government, former Google CEO Eric Schmidt, and leading
            foundations.
          </P>
          <P>
            To learn more, read <A href="/our-story/">why we started Upsolve</A> in 2016,{" "}
            <A href="/reviews/">our reviews</A> from past users, and our <A href="/press/">press coverage</A> from
            places like the New York Times and Wall Street Journal.
          </P>
        </Page>
      </AboutContainer>
    );
  }
}

export default AboutPanel;
