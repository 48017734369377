import styled from "styled-components";
import { ACSS } from "@upsolve/ui";

const Page = styled.div`
  position: relative;
  width: 100%;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: ${(props) => props.padding || "4em 1em"};
  ${(props) => (props.align === "center" ? "text-align: center;" : null)}
  a {
    ${ACSS}
  }
`;

export default Page;
