import Img from "gatsby-image";
import React, { Component } from "react";
import { withAmp } from "../AMP/AmpContext";

class ImageInContext extends Component {
  render() {
    const {
      // General
      alt,
      amp,
      className,
      src,
      // Gatsby Image
      fluid,
      loading, // auto, eager, lazy
    } = this.props;
    // If nothing provided, return null
    if (src == null && fluid == null) return null;

    // If amp or no fluid was provided, use src property
    const useSrc = amp === true || fluid == null;

    return useSrc === true ? (
      <img className={className} alt={alt} src={src.replace(/^\/\//, "https://")} />
    ) : (
      <Img alt={alt} className={className} fluid={fluid} loading={loading || "auto"} />
    );
  }
}

export default withAmp(ImageInContext);
