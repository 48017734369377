import { get, forOwn, isObject } from "lodash";

import getConstantComponentValue from "./getConstantComponentValue";

// TODO: This probably needs cleaning up but it works
export default function richTextNodeToString(originalNode, stringLimit) {
  if (originalNode == null || originalNode.content == null) return "";
  let finalStr = "";

  const traverse = (node) => {
    if (stringLimit > 0 && finalStr.length > stringLimit) return;
    forOwn(node, (val, key) => {
      if (Array.isArray(val)) {
        val.forEach((el) => {
          if (el.content != null) {
            el.content.forEach((c) => traverse(c));
          } else {
            traverse(el);
          }
        });
      } else if (isObject(val)) {
        traverse(val);
      } else {
        // leaf nodes
        if (key === "value" && node.nodeType === "text") {
          finalStr += val;
        } else if (node.nodeType === "embedded-entry-inline") {
          // - Constants
          if (get(node.data, "target.sys.contentType.sys.id") === "constantComponent") {
            if (getConstantComponentValue({ key: get(node.data, "target.fields.type['en-US']") })) {
              return getConstantComponentValue({ key: get(node.data, "target.fields.type['en-US']") });
            } else {
              return "";
            }
          }
        } else if (node.nodeType === "embedded-entry-block") {
          // FAQ Block
          if (get(node.data, "target.fields.questions['en-US']", []).length > 0) {
            traverse(get(node.data, "target.fields.questions['en-US']"));
          }
          // How-To Block
          if (get(node.data, "target.fields.steps['en-US']", []).length > 0) {
            traverse(get(node.data, "target.fields.steps['en-US']"));
          }
        }
      }
    });
  };
  traverse(get(originalNode, "content") || originalNode);

  return finalStr.length > stringLimit ? `${finalStr.slice(0, stringLimit)}...` : finalStr;
}
