import reviews from "../../../content/onBuild/reviews.json";
import upsolveJSONLD from "./upsolveJSONLD";

const ratingsJSONLD = {
  ...upsolveJSONLD,
  aggregateRating: {
    "@type": "AggregateRating",
    // TODO: Consider counts/scores from other sources besides Contentful
    ratingValue: reviews.contentful.averageRating,
    bestRating: reviews.contentful.bestRating,
    reviewCount: reviews.contentful.reviewCount,
  },
};

export default ratingsJSONLD;
