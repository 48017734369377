import React from "react";
import styled from "styled-components";

import AboutPanel from "./AboutPanel";
import LearnListingPanel from "../Learn/LearnListingPanel";

// Titles should also be links (?) alt would be accordions on mobile or just a super long footer (v0)
//v0: long footer
//v1: titles link out to top item or open triage
const columns: { title: string; items: { name: string; url: string }[] }[][] = [
  [
    {
      title: "Debt Relief Tools",
      items: [
        { name: "File Bankruptcy", url: "https://my.upsolve.org" },
        { name: "Send a Debt Validation Letter (coming soon)", url: "" },
        { name: "Settle a Debt (coming soon)", url: "" },
        { name: "Build Credit (coming soon)", url: "" },
        { name: "Debt Advisor (coming soon)", url: "" },
      ],
    },
  ],
  [
    {
      title: "Education",
      items: [
        { name: "Learning Center", url: "/learn" },
        { name: "Youtube", url: "https://www.youtube.com/upsolve" },
      ],
    },
    {
      title: "Community",
      items: [{ name: "Join Now (verified members)", url: "https://facebook.com/upsolvebankruptcy" }],
    },
    {
      title: "Advocacy",
      items: [{ name: "The American Justice Movement", url: "https://www.americanjusticemovement.org/" }],
    },
  ],
  [
    {
      title: "About Us",
      items: [
        { name: "Team", url: "/team" },
        { name: "Our Story", url: "/our-story" },
        { name: "Press", url: "/press" },
        { name: "Donate", url: "/donate" },
        { name: "Careers", url: "/careers" },
        { name: "Contact Us", url: "/contact" },
      ],
    },
  ],
];
const Footer = (props: { showArticles?: boolean; bio?: boolean }) => {
  const { bio = true } = props;
  return (
    <div>
      {props.showArticles === true && <LearnListingPanel />}
      {bio !== false && <AboutPanel />}
      <FooterContainer>
        <div className="footer__directory">
          {columns.map((column) => (
            <div className="footer__directory__column">
              {column.map((columnSection) => (
                <div className="footer__directory__column__section">
                  <h6 className="footer__directory__column__title">{columnSection.title}</h6>
                  <div className="footer__directory__column__section__links">
                    {columnSection.items.map((item) => (
                      <a
                        className={`footer__directory__column__section__link${!item.url ? " inactive" : ""}`}
                        aria-busy="false"
                        href={item.url}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="footer__disclaimer">
          Upsolve is a 501(c)(3) nonprofit organization (EIN 82-1736267) that provides free digital tools to help you
          resolve your debt and rebuild your credit. Upsolve isn’t a lawyer and{" "}
          <a href="https://upsolve.org/no-legal-advice/">can’t provide any form of legal advice.</a> You can{" "}
          <a href="https://upsolve.org/find-a-lawyer">request a free consultation</a> with a law firm in our network if
          you need legal advice. You don’t enter any form of attorney-client relationship with Upsolve by using our site
          or tools. No communication between you and Upsolve should be considered legal advice. Your use of Upsolve is
          subject to our <a href="https://upsolve.org/terms-of-use/">Terms of Use</a> and{" "}
          <a href="https://upsolve.org/terms-of-service/">Terms of Service</a>. © {new Date().getFullYear()}.
        </div>
      </FooterContainer>
    </div>
  );
};

const FooterContainer = styled.footer`
  background: ${(props) => props.theme.colors.brand[800]};
  color: black;
  display: flex;
  flex-direction: column;
  padding: 64px;
  gap: 112px;
  @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
    padding: 30px;
    gap: 40px;
    /* room for the sticky mobile menu */
    padding-bottom: 86px;
  }
  .footer__directory {
    display: flex;
    flex: 1;
    gap: 60px;
    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      flex-direction: column;
      gap: 40px;
    }

    .footer__directory__column {
      display: flex;
      flex-direction: column;
      gap: 40px;
      flex: 1;

      .footer__directory__column__section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: black;
        .footer__directory__column__title {
          color: black;
          font-weight: 400;
          font-size: 16px;
          border-bottom: 1px solid ${(p) => p.theme.colors.gray[800]};
          width: 100%;
          max-width: 330px;
        }
        .footer__directory__column__section__links {
          display: flex;
          flex-direction: column;
          gap: 12px;
          @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
            gap: 4px;
          }
        }
        .footer__directory__column__section__link {
          color: black;
          font-weight: 200;
          font-size: 16px;
          a:visited,
          a:active,
          a:hover {
            color: black;
          }
          &:hover {
            text-decoration: underline;
          }
        }
        .inactive {
          color: ${(p) => p.theme.colors.gray[800]};
          cursor: none !important;
          pointer-events: none;
          a:visited,
          a:active,
          a:hover {
            color: ${(p) => p.theme.colors.brand[700]} !important;
          }
          &:hover {
            text-decoration: none !important;
          }
        }
      }
    }
  }
  .footer__disclaimer {
    color: black;
    font-weight: 200;
    font-size: 16px;
    a,
    a:visited,
    a:active,
    a:hover {
      color: black;
      text-decoration: underline;
    }
  }
`;

export default Footer;
