import { useState } from 'react';

const useModal = () => {
  const [modalState, setModalState] = useState({
    component: undefined,
    isOpen: false,
  });

  const setIsOpen = (isOpen: boolean, component?: any) => {
    setModalState({
      isOpen: isOpen && component != null,
      component: component ? isOpen ? component : undefined : undefined,
    });
  };

  return {
    ...modalState,
    setIsOpen,
  };
};

export default useModal;
