import { get, kebabCase } from "lodash";
import richTextNodeToString from "./richTextNodeToString";

// Expect a rich text node that has a content array prop
export default function getHowToFromRichText({ content = [] }, { baseUrl, locale = "en-US" }) {
  const howToNode = content.find((c) => get(c, "data.target.sys.contentType.sys.id") === "howTo");
  if (howToNode == null) return null;

  return {
    type: get(howToNode, `data.target.fields.type['${locale}']`),
    title: get(howToNode, `data.target.fields.title['${locale}']`),
    description: get(howToNode, `data.target.fields.description['${locale}']`, ""),
    duration: get(howToNode, `data.target.fields.duration['${locale}']`, ""),
    supplies: get(howToNode, `data.target.fields.supplies['${locale}']`, []),
    tools: get(howToNode, `data.target.fields.tools['${locale}']`, []),
    estimatedCost: get(howToNode, `data.target.fields.estimatedCost['${locale}']`, 0),
    steps: get(howToNode, `data.target.fields.steps['${locale}']`, []).map((step) => {
      return {
        title: get(step, `fields.title['${locale}']`),
        url: `${baseUrl != null ? `https://upsolve.org${baseUrl}` : ""}#${kebabCase(
          get(step, `fields.title['${locale}']`)
        )}`,
        directions: [richTextNodeToString(get(step, `fields.content[${locale}]`))],
        tips: [],
      };
    }),
  };
}
