import getMyUpsolveEntryUrl from "../Links/getMyUpsolveEntryUrl";
import { CTATrackingTarget } from "../analytics/track";
import upsolveStats from "../../../content/onBuild/upsolveStats.json";
import { P } from "@upsolve/ui";
import React from "react";

export type TCtaCopy = {
  title: string;
  description: React.ReactNode;
  buttonText: string;
  mobileFooterText: string;
};

//for analytics
export enum CTATarget {
  SCREENER = "Screener",
  AJM = "AJM",
  STUDENT_LOANS = "Student Loans",
  DEBT_TRIAGE = "Debt Triage",
}

export const getCTARenderedCopy: (targetEntity: CTATarget) => TCtaCopy = (targetEntity: CTATarget) => {
  const entityToData: {
    [key in CTATarget]: TCtaCopy;
  } = {
    [CTATarget.SCREENER]: {
      title: "Considering Bankruptcy?",
      description: (
        <P>
          <b>
            {`Our free tool has helped ${upsolveStats.filings.total.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}+ families file bankruptcy on their own. `}
          </b>
          We're funded by Harvard University and will never ask you for a credit card or payment.
        </P>
      ),
      buttonText: "Explore Free Tool",
      mobileFooterText: "Get Free Help",
    },
    [CTATarget.AJM]: {
      title: "Been Sued for Debt?",
      description:
        "If you're a New York resident and want to receive free legal advice on your debt collection lawsuit, we can help!",
      buttonText: "Get Free Help",
      mobileFooterText: "Get Free Help",
    },
    [CTATarget.STUDENT_LOANS]: {
      title: "We can help you ask to get your student loans erased",
      description: "If you qualify, Upsolve can help you file bankruptcy and ask to get rid of your student loan debt.",
      buttonText: "Check Eligibility",
      mobileFooterText: "Student Loans Help",
    },
    [CTATarget.DEBT_TRIAGE]: {
      title: "In Debt?",
      description: "Our nonprofit helps you get out of debt with free debt relief tools and education.",
      buttonText: "Get Free Help",
      mobileFooterText: "Get Free Help",
    },
  };
  return entityToData[targetEntity || CTATarget.SCREENER];
};

//TODO: at some point we should refactor the utm and screenerParams to both use for the same data
export const getCTAURL = (targetEntity: CTATarget): string | null => {
  const entityToData: { [key in CTATarget]: string | null } = {
    [CTATarget.SCREENER]: getMyUpsolveEntryUrl(),
    [CTATarget.AJM]: `https://vault.netvoyage.com/neWeb2/delView.aspx?env=%2FQ9%2Fp%2Fg%2Fa%2F9%2F%5ER231024021550891.nev&dn=1&v=0&dl=1&p=0&e=30240104&t=Eg%2BiudOMK%2BYFirKw3ADzOSAeJyI%3D&cg=NG-Y1SSG4EC&hd=1&nf=N&s=VAULT-68E98DJ9`,
    [CTATarget.STUDENT_LOANS]: getMyUpsolveEntryUrl(true),
    [CTATarget.DEBT_TRIAGE]: null,
  };
  return targetEntity != CTATarget.DEBT_TRIAGE ? entityToData[targetEntity || CTATarget.SCREENER] : null;
};

export const getCTATrackingTarget = (targetEntity: CTATarget) => {
  const entityToData: { [key in CTATarget]: CTATrackingTarget } = {
    [CTATarget.SCREENER]: CTATrackingTarget.SCREENER,
    [CTATarget.AJM]: CTATrackingTarget.AJM,
    [CTATarget.STUDENT_LOANS]: CTATrackingTarget.STUDENT_LOANS,
    [CTATarget.DEBT_TRIAGE]: CTATrackingTarget.DEBT_TRIAGE,
  };
  return entityToData[targetEntity || CTATarget.SCREENER];
};
